

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif, serif;
}
/* NAvbar */
.hidden{
  display:none
}

/* landing page carousel center */
.carousel-wrapper{
  text-align: center;
 margin-top: 50px;


}
/* landing page carousel center */


/* gallary image and pop up window */

.picture {
  width: 300px;
  height: 300px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 30px;
  left: 0;
  /* right: 0; */
  width: 100%;
  height: 100%;
  background: rgba(73, 55, 55, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal {
  position: relative;
  width: 100%;
  max-width: 850px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(33, 19, 19, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;

}

.modal-picture {
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 25px;
  color:rgb(219, 178, 175);
  cursor: pointer;
  background-color: transparent;
  border:none;
}

.previous-button {
  position: absolute;
  top:50%;
  left:0%;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color:rgb(219, 178, 175);
  margin-right: 10px;
  background-color: transparent;
}

/* .previous-button:hover {
 
} */

.next-button {
  position: absolute;
  top:50%;
  right:0%;
  border: none;
  font-size: 40px;
  color:rgb(219, 178, 175);
  cursor: pointer;
  background-color: transparent;
}

/* .next-button:hover {
  background: #555;
} */

/* gallary image and pop up window */

.carousel .control-arrow {
  display: none;
}